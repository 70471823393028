<template>
  <user-manuals></user-manuals>
</template>

<script>
import UserManuals from './UserManuals.vue';

export default {
  components: {
    UserManuals,
  },
};
</script>
